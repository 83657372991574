import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import SiteFrame from './SiteFrame/SiteFrame'
import SidebarButton from './SiteFrame/SidebarButton'
import quest_img from './img/quest.svg'
import character_img from './img/character.png'
import story_img from './img/story.svg'
import location_img from './img/location.svg'
import event_img from './img/event.svg'
import settings_img from './img/settings.svg'
import ProfileList from './Profile/ProfileList'
import CrumbRoute from './BreadCrumb/CrumbRoute'
import Breadcrumbs from './BreadCrumb/Breadcrumbs'
import Breadcrumb from './BreadCrumb/Breadcrumb'
import QuestPage from './QuestPage';
import DatabaseLoader from './DatabaseLoader';
import { profileApi, worldApi } from './Api'
import ProfileRenderer from './Profile/ProfileRenderer';
import WorldList from './World/WorldList';
import { WorldRenderer } from './World/WorldRenderer';

function App() {
    return (
        <Router>
            <SiteFrame
                sidebarButtons={
                    <>
                        <SidebarButton label="Quests" route="/quest" icon={quest_img} />
                        <SidebarButton label="Profiles" route="/profile" icon={character_img} />
                        <SidebarButton label="Story" route="/story" icon={story_img} />
                        <SidebarButton label="Locations" route="/location" icon={location_img} />
                        <SidebarButton label="Events" route="/event" icon={event_img} />
                        <SidebarButton label="Settings" route="/setting" icon={settings_img} />
                    </>
                }
            >
                <Breadcrumbs wrapper={val => <>You are here: {val.children}</>} separator=" > " />

                <Breadcrumb data={{ title: "WISE", pathname: "/" }}>
                    <Switch>
                        <Route exact path="/">
                            <h1>Home Page</h1>
                        </Route>
                        <CrumbRoute title="Quests" path="/quest">
                            <h1>Quests</h1>
                            <QuestPage />
                        </CrumbRoute>
                        <CrumbRoute title="Profile" path="/profile">
                            <Route exact path="/profile">
                                <ProfileList />
                            </Route>
                            <Route path="/profile/:id" render={(props) => (
                                <DatabaseLoader api={profileApi} uuid={props.match.params.id} render={(profile) => (
                                    <Breadcrumb data={{ title: profile.username ?? "Unknown profile", pathname: props.match.url }} >
                                        <ProfileRenderer profile={profile} />
                                    </Breadcrumb>
                                )} />
                            )} />
                        </CrumbRoute>
                        <CrumbRoute title="Story" path="/story">
                            <h1>Story</h1>
                        </CrumbRoute>
                        <CrumbRoute title="Locations" path="/location">
                            <Route exact path="/location">
                                    <WorldList />
                            </Route>
                            <Route path="/location/world/:id" render={(props) => (
                                <DatabaseLoader api={worldApi} uuid={props.match.params.id} render={(world) => (
                                    <Breadcrumb data={{ title: world.name ?? "Unknown world", pathname: props.match.url }} >
                                        <WorldRenderer world={world} />
                                    </Breadcrumb>
                                )} />
                            )} />
                        </CrumbRoute>
                        <CrumbRoute title="Events" path="/event">
                            <h1>Events</h1>
                        </CrumbRoute>
                        <CrumbRoute title="Settings" path="/setting">
                            <h1>Settings</h1>
                        </CrumbRoute>
                    </Switch>
                </Breadcrumb>
            </SiteFrame>
        </Router>
    )
}

export default App;
