/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * Wellandel Information System Editor API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This file is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the file manually.
 */

import * as url from "url";
import * as portableFetch from "portable-fetch";
import { Configuration } from "./configuration";

const BASE_PATH = "/".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration?: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = portableFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: string = "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface DocumentRef
 */
export interface DocumentRef {
    /**
     * 
     * @type {string}
     * @memberof DocumentRef
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentRef
     */
    collection: string;
}
/**
 * 
 * @export
 * @interface Profile
 */
export interface Profile {
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    username?: string;
    /**
     * 
     * @type {number}
     * @memberof Profile
     */
    playtime: number;
    /**
     * 
     * @type {DocumentRef}
     * @memberof Profile
     */
    defaultCharacter?: DocumentRef;
    /**
     * 
     * @type {Date}
     * @memberof Profile
     */
    firstJoined: Date;
}
/**
 * 
 * @export
 * @interface Progression
 */
export interface Progression {
    /**
     * 
     * @type {string}
     * @memberof Progression
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Progression
     */
    name?: string;
    /**
     * 
     * @type {Array<ProgressionNode>}
     * @memberof Progression
     */
    nodes?: Array<ProgressionNode>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Progression
     */
    requiredArguments?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Progression
     */
    optionalArguments?: Array<string>;
}
/**
 * 
 * @export
 * @interface ProgressionNode
 */
export interface ProgressionNode {
    /**
     * 
     * @type {string}
     * @memberof ProgressionNode
     */
    nodeType: string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof ProgressionNode
     */
    attributes?: { [key: string]: any; };
}
/**
 * 
 * @export
 * @interface World
 */
export interface World {
    /**
     * 
     * @type {string}
     * @memberof World
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof World
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof World
     */
    shorthand?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof World
     */
    aliasUUIDs?: Array<string>;
}
/**
 * ProfileApi - fetch parameter creator
 * @export
 */
export const ProfileApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get all profiles
         * @summary Get profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile(options: any = {}): FetchArgs {
            const localVarPath = `/profile`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a specific profile
         * @summary Get profile with profileUuid
         * @param {string} profileUuid Profile UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileWithProfileUuid(profileUuid: string, options: any = {}): FetchArgs {
            // verify required parameter 'profileUuid' is not null or undefined
            if (profileUuid === null || profileUuid === undefined) {
                throw new RequiredError('profileUuid','Required parameter profileUuid was null or undefined when calling getProfileWithProfileUuid.');
            }
            const localVarPath = `/profile/{profile-uuid}`
                .replace(`{${"profile-uuid"}}`, encodeURIComponent(String(profileUuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProfileApi - functional programming interface
 * @export
 */
export const ProfileApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Get all profiles
         * @summary Get profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Profile>> {
            const localVarFetchArgs = ProfileApiFetchParamCreator(configuration).getProfile(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Get a specific profile
         * @summary Get profile with profileUuid
         * @param {string} profileUuid Profile UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileWithProfileUuid(profileUuid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Profile> {
            const localVarFetchArgs = ProfileApiFetchParamCreator(configuration).getProfileWithProfileUuid(profileUuid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ProfileApi - factory interface
 * @export
 */
export const ProfileApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Get all profiles
         * @summary Get profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile(options?: any) {
            return ProfileApiFp(configuration).getProfile(options)(fetch, basePath);
        },
        /**
         * Get a specific profile
         * @summary Get profile with profileUuid
         * @param {string} profileUuid Profile UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileWithProfileUuid(profileUuid: string, options?: any) {
            return ProfileApiFp(configuration).getProfileWithProfileUuid(profileUuid, options)(fetch, basePath);
        },
    };
};

/**
 * ProfileApi - object-oriented interface
 * @export
 * @class ProfileApi
 * @extends {BaseAPI}
 */
export class ProfileApi extends BaseAPI {
    /**
     * Get all profiles
     * @summary Get profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public getProfile(options?: any) {
        return ProfileApiFp(this.configuration).getProfile(options)(this.fetch, this.basePath);
    }

    /**
     * Get a specific profile
     * @summary Get profile with profileUuid
     * @param {string} profileUuid Profile UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public getProfileWithProfileUuid(profileUuid: string, options?: any) {
        return ProfileApiFp(this.configuration).getProfileWithProfileUuid(profileUuid, options)(this.fetch, this.basePath);
    }

}
/**
 * ProgressionApi - fetch parameter creator
 * @export
 */
export const ProgressionApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get all progressions
         * @summary Get progression
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgression(options: any = {}): FetchArgs {
            const localVarPath = `/progression`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a specific profile
         * @summary Get progression with progressionUuid
         * @param {string} progressionUuid Progression UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgressionWithProgressionUuid(progressionUuid: string, options: any = {}): FetchArgs {
            // verify required parameter 'progressionUuid' is not null or undefined
            if (progressionUuid === null || progressionUuid === undefined) {
                throw new RequiredError('progressionUuid','Required parameter progressionUuid was null or undefined when calling getProgressionWithProgressionUuid.');
            }
            const localVarPath = `/progression/{progression-uuid}`
                .replace(`{${"progression-uuid"}}`, encodeURIComponent(String(progressionUuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProgressionApi - functional programming interface
 * @export
 */
export const ProgressionApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Get all progressions
         * @summary Get progression
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgression(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Progression>> {
            const localVarFetchArgs = ProgressionApiFetchParamCreator(configuration).getProgression(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Get a specific profile
         * @summary Get progression with progressionUuid
         * @param {string} progressionUuid Progression UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgressionWithProgressionUuid(progressionUuid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Progression> {
            const localVarFetchArgs = ProgressionApiFetchParamCreator(configuration).getProgressionWithProgressionUuid(progressionUuid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ProgressionApi - factory interface
 * @export
 */
export const ProgressionApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Get all progressions
         * @summary Get progression
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgression(options?: any) {
            return ProgressionApiFp(configuration).getProgression(options)(fetch, basePath);
        },
        /**
         * Get a specific profile
         * @summary Get progression with progressionUuid
         * @param {string} progressionUuid Progression UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgressionWithProgressionUuid(progressionUuid: string, options?: any) {
            return ProgressionApiFp(configuration).getProgressionWithProgressionUuid(progressionUuid, options)(fetch, basePath);
        },
    };
};

/**
 * ProgressionApi - object-oriented interface
 * @export
 * @class ProgressionApi
 * @extends {BaseAPI}
 */
export class ProgressionApi extends BaseAPI {
    /**
     * Get all progressions
     * @summary Get progression
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgressionApi
     */
    public getProgression(options?: any) {
        return ProgressionApiFp(this.configuration).getProgression(options)(this.fetch, this.basePath);
    }

    /**
     * Get a specific profile
     * @summary Get progression with progressionUuid
     * @param {string} progressionUuid Progression UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProgressionApi
     */
    public getProgressionWithProgressionUuid(progressionUuid: string, options?: any) {
        return ProgressionApiFp(this.configuration).getProgressionWithProgressionUuid(progressionUuid, options)(this.fetch, this.basePath);
    }

}
/**
 * WorldApi - fetch parameter creator
 * @export
 */
export const WorldApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get all worlds
         * @summary Get world
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorld(options: any = {}): FetchArgs {
            const localVarPath = `/world`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a specific world
         * @summary Get world with worldUuid
         * @param {string} worldUuid Progression UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorldWithWorldUuid(worldUuid: string, options: any = {}): FetchArgs {
            // verify required parameter 'worldUuid' is not null or undefined
            if (worldUuid === null || worldUuid === undefined) {
                throw new RequiredError('worldUuid','Required parameter worldUuid was null or undefined when calling getWorldWithWorldUuid.');
            }
            const localVarPath = `/world/{world-uuid}`
                .replace(`{${"world-uuid"}}`, encodeURIComponent(String(worldUuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorldApi - functional programming interface
 * @export
 */
export const WorldApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Get all worlds
         * @summary Get world
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorld(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<World>> {
            const localVarFetchArgs = WorldApiFetchParamCreator(configuration).getWorld(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Get a specific world
         * @summary Get world with worldUuid
         * @param {string} worldUuid Progression UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorldWithWorldUuid(worldUuid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<World> {
            const localVarFetchArgs = WorldApiFetchParamCreator(configuration).getWorldWithWorldUuid(worldUuid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * WorldApi - factory interface
 * @export
 */
export const WorldApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Get all worlds
         * @summary Get world
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorld(options?: any) {
            return WorldApiFp(configuration).getWorld(options)(fetch, basePath);
        },
        /**
         * Get a specific world
         * @summary Get world with worldUuid
         * @param {string} worldUuid Progression UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorldWithWorldUuid(worldUuid: string, options?: any) {
            return WorldApiFp(configuration).getWorldWithWorldUuid(worldUuid, options)(fetch, basePath);
        },
    };
};

/**
 * WorldApi - object-oriented interface
 * @export
 * @class WorldApi
 * @extends {BaseAPI}
 */
export class WorldApi extends BaseAPI {
    /**
     * Get all worlds
     * @summary Get world
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorldApi
     */
    public getWorld(options?: any) {
        return WorldApiFp(this.configuration).getWorld(options)(this.fetch, this.basePath);
    }

    /**
     * Get a specific world
     * @summary Get world with worldUuid
     * @param {string} worldUuid Progression UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorldApi
     */
    public getWorldWithWorldUuid(worldUuid: string, options?: any) {
        return WorldApiFp(this.configuration).getWorldWithWorldUuid(worldUuid, options)(this.fetch, this.basePath);
    }

}
