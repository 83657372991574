import React from 'react';

import Blockly from 'blockly/core';
import locale from 'blockly/msg/en';
import 'blockly/blocks';
import { v4 as uuid } from 'uuid'
import './BlocklyComponent.css'

Blockly.setLocale(locale);

interface Props extends React.PropsWithChildren<Blockly.BlocklyOptions> {
    initialXml: string
}

declare global {
    namespace JSX {
        interface IntrinsicElements {
            "xml": any
        }
    }
}

// Blockly IDs should be UUIDs to match DB
Blockly.utils.genUid = uuid

interface MainFuncArg {
    name: string,
    assignedVariable: string,
    type: string
}

Blockly.Blocks['main'] = {
    functionArguments: [] as MainFuncArg[],
    init: function () {
        this.appendDummyInput()
            .appendField("Main Function, with")
        this.appendStatementInput("CODE").setCheck(null)
        this.setDeletable(false)
        this.setColour(290)
    },
    domToMutation: function (xmlElement: Element) {
        let args = xmlElement.getElementsByTagName("argument")
        for (let i = 0; i < args.length; i++) {
            let name = args[i].getAttribute("name")
            let type = args[i].getAttribute("type")
            if (name == null || type == null)
                throw new Error("Missing attributes for Main function argument")
            this.functionArguments[i] = {
                name: name,
                type: type,
                assignedVariable: args[i].getAttribute("assignedVariable") ?? name
            }
        }
        this.updateShape()
    },
    mutationToDom: function () {
        let container = document.createElement("mutation")
        this.functionArguments.forEach((arg: MainFuncArg) => {
            let argElem = document.createElement("argument")
            argElem.setAttribute("name", arg.name)
            argElem.setAttribute("type", arg.type)
            argElem.setAttribute("assignedVariable", arg.assignedVariable)
        });
        return container
    },
    updateShape: function () {
        this.functionArguments.forEach((arg: MainFuncArg) => {
            let inputName = "arg-" + arg.name
            this.appendDummyInput(inputName)
                .appendField(arg.name + " as ")
                .appendField(new Blockly.FieldVariable(arg.assignedVariable))
            this.moveInputBefore(inputName, "CODE")
        }, this);
    }
} as any

class BlocklyComponent extends React.Component<Props> {
    private blocklyDiv: React.RefObject<HTMLDivElement> | null = null
    private toolbox: React.RefObject<any> | null = null
    private primaryWorkspace: Blockly.Workspace | null = null
    private text = "test"

    constructor(props: Props) {
        super(props)
        this.toolbox = React.createRef()
        this.blocklyDiv = React.createRef()
    }

    componentDidMount() {
        const { initialXml, children, ...rest } = this.props;

        if (this.blocklyDiv == null) {
            throw new Error()
        } else if (this.toolbox == null) {
            throw new Error()
        } else if (this.blocklyDiv.current == null) {
            throw new Error()
        } else if (this.toolbox.current == null) {
            throw new Error()
        }

        this.primaryWorkspace = Blockly.inject(
            this.blocklyDiv.current,
            {
                toolbox: this.toolbox.current ?? undefined,
                ...rest
            },
        )

        if (initialXml) {
            Blockly.Xml.domToWorkspace(Blockly.Xml.textToDom(initialXml), this.primaryWorkspace);
        }
    }

    get workspace(): Blockly.Workspace {
        return this.primaryWorkspace as Blockly.Workspace;
    }

    setXml(xml: string) {
        if (this.primaryWorkspace == null)
            throw new Error("Workspace is null")

        Blockly.Xml.domToWorkspace(Blockly.Xml.textToDom(xml), this.primaryWorkspace)
    }

    render() {
        const { children } = this.props

        return (
            <React.Fragment>
                <div ref={this.blocklyDiv} id="blocklyDiv" />
                <xml is="blockly" style={{ display: 'none' }} ref={this.toolbox}>
                    {children}
                </xml>
            </React.Fragment>
        )
    }
}

export default BlocklyComponent;
