import {BaseAPI, Profile, ProfileApi as SwaggerProfileApi, World, WorldApi as SwaggerWorldApi} from "./wise-api/api"
import { Configuration } from "./wise-api/configuration"

abstract class WiseApi<API extends BaseAPI, T> {
    private readonly config: Configuration = new Configuration({basePath:"http://localhost:8080"})
    private api: API

    constructor(apiCreator: ((c: Configuration) => API)) {
        this.api = apiCreator(this.config)
    }

    public abstract getAll(): Promise<T[]>

    public abstract getOne(uuid: string): Promise<T>

    public getApi() {
        return this.api
    }
}

let profileApi = new class extends WiseApi <SwaggerProfileApi, Profile> {
    constructor() {
        super(c => new SwaggerProfileApi(c))
    }

    public getAll(): Promise<Profile[]> {
        return this.getApi().getProfile()
    }
    public getOne(uuid: string): Promise<Profile> {
        return this.getApi().getProfileWithProfileUuid(uuid)
    }
}()

let worldApi = new class extends WiseApi<SwaggerWorldApi, World> {
    constructor() {
        super(c => new SwaggerWorldApi(c))
    }

    public getAll(): Promise<World[]> {
        return this.getApi().getWorld()
    }
    public getOne(uuid: string): Promise<World> {
        return this.getApi().getWorldWithWorldUuid(uuid)
    }
}()

export { WiseApi, profileApi, worldApi }
