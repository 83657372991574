import { Component, ReactNode } from "react"
import {WiseApi} from "./Api"

interface Props<T> {
    uuid: string,
    api: WiseApi<any, T>,
    render: (component: T) => ReactNode
}

interface State<T> {
    error: any
    loadedComponent: T
}

class DatabaseLoader<T> extends Component<Props<T>, State<T>> {
    componentDidMount() {
        this.props.api.getOne(this.props.uuid)
            .then(c => this.setState({ loadedComponent: c }))
            .catch(r => {
                console.log(r)
                this.setState({ error: r })
            })
    }

    render() {
        if(!this.state) {
            return <h1>Loading...</h1>
        } else if(this.state.error) {
            return <h1>{this.state?.error?.statusText}</h1>
        } else {
            return this.props.render(this.state.loadedComponent)
        }
    }
}

export default DatabaseLoader
