import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

interface Props {
    route: string
    label: string
    icon: string
}

class SidebarButton extends Component<Props, {}> {
    render() {
        return (
            <li>
                <NavLink activeClassName="active" to={this.props.route}>
                    <img src={this.props.icon} alt={this.props.label} />
                    {this.props.label}
                </NavLink>
            </li>
        )
    }
}

export default SidebarButton
