import React, { Component, ReactNode } from 'react'
import './Sidebar.css'

interface Props {
    collapsed: boolean
    onToggle: (collapsed: boolean) => void
    sidebarButtons: ReactNode
}

interface State {
    collapsed: boolean
}

class Sidebar extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = { collapsed: props.collapsed }
        this.toggleSidebar = this.toggleSidebar.bind(this)
    }

    static defaultProps = { collapsed: true, onToggle: () => {} }

    private toggleSidebar() {
        let collapsed = !this.state.collapsed
        this.setState({ collapsed: collapsed })
        this.props.onToggle(collapsed)
    }

    render() {
        return (
            <nav id="sidebar" className={this.state.collapsed ? "collapsed" : undefined}>
                <div id="sidebar-expander" onClick={this.toggleSidebar} />
                <ul>
                    {this.props.sidebarButtons}
                </ul>
            </nav>
        )
    }
}

export default Sidebar
