import React from 'react';
import './App.css';

import BlocklyComponent from './Blockly/BlocklyComponent'

import Blockly from 'blockly';

import xml2json from './jsonxml/xml2json'
import json2xml from './jsonxml/json2xml'

const Block = (p: any) => {
    const { children, ...props } = p
    props.is = "blockly"
    return React.createElement("block", props, children)
}

const Category = (p: any) => {
    const { children, ...props } = p;
    props.is = "blockly";
    return React.createElement("category", props, children);
}

const Value = (p: any) => {
    const { children, ...props } = p
    props.is = "blockly"
    return React.createElement("value", props, children)
}

const Field = (p: any) => {
    const { children, ...props } = p
    props.is = "blockly"
    return React.createElement("field", props, children)
}

const Shadow = (p: any) => {
    const { children, ...props } = p
    props.is = "blockly"
    return React.createElement("shadow", props, children)
}


class App extends React.Component {
    private simpleWorkspace: React.RefObject<BlocklyComponent> = React.createRef()

    generateCode = () => {
        var code = Blockly.Xml.workspaceToDom(
            (this.simpleWorkspace.current as BlocklyComponent).workspace
        )
        let json = xml2json(code, " ");
        console.log(json);
        console.log(json2xml(JSON.parse(json)))
    }

    render() {
        return (
            <React.Fragment>
                <button onClick={this.generateCode}>Convert</button>
                <BlocklyComponent ref={this.simpleWorkspace}
                    readOnly={false} trashcan={true} sounds={false}
                    move={{
                        scrollbars: false,
                        drag: true,
                        wheel: true
                    }}
                    initialXml={`
                        <xml xmlns="http://www.w3.org/1999/xhtml">
                        <block type="main" x="0" y="0">
                            <mutation>
                                <argument name="Target" type="Profile" />
                                <argument name="Position" type="Location" />
                            </mutation>
                        </block>
                        </xml>`
                    }
                >
                    <Category name="Test Blocks" categorystyle="logic_category">
                        <Block type="controls_if" />
                        <Block type="controls_repeat_ext">
                            <Value name="TIMES">
                                <Block type="math_number">
                                    <Field name="NUM">10</Field>
                                </Block>
                            </Value>
                        </Block>
                        <Block type="procedures_defnoreturn"></Block>
                    </Category>
                    <Category name="Variables" custom="VARIABLE" />
                    <Category name="Helper Procedures" custom="PROCEDURE" />
                </BlocklyComponent>
            </React.Fragment>
        );
    }
}

export default App;
