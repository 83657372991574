import { World } from "../wise-api";

export const WorldRenderer = (props: {world: World}) => {
    let altUuid: number = props.world.aliasUUIDs?.length ?? 0
    return (
        <>
            <h1>{props.world.name}</h1>
            <br />
                    Shorthand: {props.world.shorthand ?? "None"}
            <br />
                    Has {altUuid} alternative UUID{altUuid !== 1 ? "s" : null}
        </>
    )
}
