import { Route, RouteComponentProps, RouteProps } from 'react-router-dom';
import Breadcrumb from './Breadcrumb';

type Props = RouteProps & Idk
interface Idk {
    includeSearch?: boolean,
    render?: ((prop: RouteComponentProps<any>) => Element),
    title: string
}

let CrumbRoute = ({
    includeSearch = false,
    render,
    title,
    children,
    ...props
}: Props) => (
    <Route {...props} render={routeProps => (
        <Breadcrumb data={{
            title: title,
            pathname: routeProps.match.url,
            search: includeSearch ? routeProps.location.search : undefined
        }}>
            { children ? children : (render ? render(routeProps) : null)}
        </Breadcrumb>
    )} />
)

export default CrumbRoute
