// Import External Dependencies
import { Component, PropsWithChildren } from 'react'
import { v4 as UUID } from 'uuid'
import isEqual from 'lodash.isequal'

// Import Utilities
import { Dispatch } from './Store'

export type CrumbData = Data & State

interface Data {
	title: string,
	pathname: string,
	search?: string,
	state?: any
}

interface Props {
    data: Data,
	hidden: boolean,
}

interface State {
	id: string
}

// Create and export the component
export default class Breadcrumb extends Component<PropsWithChildren<Props>, State> {
	static defaultProps = {
		hidden: false
    }
    
    constructor(props: Props) {
        super(props)
        this.state = { id: UUID() }
        this.dispatch = this.dispatch.bind(this)
    }

	render() {
		return this.props.children
	}

	componentDidMount() {
        if ( !this.props.hidden )
            this.dispatch('ADD_CRUMB', this.props.data)
	}

	componentDidUpdate(prevProps: Props) {
		// Update the crumb if its data has changed
		if ( !isEqual(this.props.data, prevProps.data) ) {
			this.dispatch('UPDATE_CRUMB', this.props.data)
		}

		// Remove/add crumb based on `hidden` prop
		if ( this.props.hidden && !prevProps.hidden ) {
			this.dispatch('REMOVE_CRUMB', this.props.data)
		} else if ( !this.props.hidden && prevProps.hidden ) {
			this.dispatch('ADD_CRUMB', this.props.data)
		}
	}

	componentWillUnmount() {
		this.dispatch(
			'REMOVE_CRUMB',
			this.props.data
		)
	}

	/**
	 * Dispatch the given `action`
	 * 
	 * @param  {string} action - A valid action name accepted by the store
	 * @param  {object} data   - The breadcrumb data to pass
	 */
	private dispatch(action: string, data: any) {
        let id = this.state.id
		Dispatch({
			type: action,
			payload: { id, ...data }
		})
	}
}
