import React, { Component, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import logo_white from '../img/logo_white.png';
import Sidebar from './Sidebar';
import './SiteFrame.css'

interface State {
    sidebarCollapsed: boolean
}

interface Props {
    sidebarButtons: ReactNode
}

class SiteFrame extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = { sidebarCollapsed: true }
    }

    render() {
        return (
            <React.Fragment>
                <header>
                    <Link to="/"><img src={logo_white} alt="Wellandel Logo" /></Link>
                    <div className="title">
                            Information System Editor (WISE)
                    </div>
                </header>
                <Sidebar
                    collapsed={true}
                    onToggle={collapsed => this.setState({ sidebarCollapsed: collapsed })}
                    sidebarButtons={this.props.sidebarButtons}
                />
                <main id="main" className={this.state.sidebarCollapsed ? "collapsed" : undefined}>
                    {this.props.children}
                </main>

                <footer>
                    Copyright &copy; 2021 <a href="https://www.wellandel.com">Wellandel Studios</a>.
                    All rights reserved.
                </footer>
            </React.Fragment>
        )
    }
}

export default SiteFrame;
