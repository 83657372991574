import React, { Component, Fragment } from 'react'
import { Profile as ApiProfile } from "../wise-api/api"
import moment from 'moment'

interface Props {
    profile: ApiProfile
}

class ProfileRenderer extends Component<Props, any> {
    render() {
        return (
            <Fragment>
                <h1>Profile of {this.props.profile.username}</h1>
                <br />
                    First seen {new Date(this.props.profile.firstJoined ?? 0).toDateString()}
                <br />
                    Has been online for {moment.duration(this.props.profile.playtime ?? 0, 'seconds').humanize()}
            </Fragment>
        )
    }
}

export default ProfileRenderer
