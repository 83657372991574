import React, { Component } from "react"
import { profileApi } from "../Api"
import { Profile as ApiProfile } from "../wise-api/api"
import { Link } from "react-router-dom"

interface State {
    profiles: ApiProfile[]
}

class ProfileList extends Component<{}, State> {
    componentDidMount() {
        profileApi.getAll()
            .then(ps => this.setState({ profiles: ps }))
    }

    render() {
        if (!this.state) {
            return (
                <h1>Loading...</h1>
            )
        } else {
            return (
                <div>
                    <h1>List of profiles</h1>
                    <br />
                    {this.state.profiles.map(p =>
                        <div key={p.id}>
                            <Link to={"profile/" + p.id}>
                                {p.username ?? "Unknown"}
                            </Link>
                        </div>
                    )}
                </div>
            )
        }
    }
}

export default ProfileList
