import React from 'react'
import { NavLink } from 'react-router-dom'
import { Unsubscribe } from 'redux'
import Store from './Store'
const block = 'breadcrumbs'

interface Props {
    className: string,
    hidden: boolean,
    separator: React.ReactNode,
    setCrumbs: (...args: any[]) => any,
    wrapper: React.FunctionComponent<{ className: string }>
}

export default class Breadcrumbs extends React.Component<React.PropsWithChildren<Props>, {}> {
    static defaultProps = {
        className: '',
        hidden: false,
        separator: '>',
        setCrumbs: undefined,
        wrapper: (props: React.PropsWithChildren<any>) => (
            <nav {...props}>
                { props.children}active
            </nav>
        )
    }

    private unsubscribe?: Unsubscribe = undefined

    render() {
        let { className, hidden, wrapper: Wrapper, setCrumbs } = this.props
        let hiddenMod = hidden ? `${block}--hidden` : ''
        let crumbs = Store.getState() ?? []

        crumbs = crumbs.sort((a, b) => {
            return a.pathname.length - b.pathname.length
        })

        if (setCrumbs)
            crumbs = setCrumbs(crumbs)

        return (
            <div className={className}>
                <Wrapper className={`${block} ${hiddenMod}`}>
                    {crumbs.map((crumb, i) => (
                        <span key={crumb.id} className={`${block}__section`}>
                            <NavLink
                                exact
                                className={`${block}__crumb`}
                                activeClassName={`${block}__crumb--active`}
                                to={{
                                    pathname: crumb.pathname,
                                    search: crumb.search,
                                    state: crumb.state
                                }}>
                                {crumb.title}
                            </NavLink>

                            { i < crumbs.length - 1 ? (
                                <span className={`${block}__separator`}>
                                    { this.props.separator}
                                </span>
                            ) : null}
                        </span>
                    ))}
                </Wrapper>

                { this.props.children}
            </div>
        )
    }

    componentDidMount() {
        this.unsubscribe = Store.subscribe(() => {
            this.forceUpdate()
        })
    }

    componentWillUnmount() {
        if (this.unsubscribe)
            this.unsubscribe()
    }
}
