import React, { Component } from "react"
import { worldApi } from "../Api"
import { World } from "../wise-api/api"
import { Link } from "react-router-dom"

interface State {
    worlds: World[]
}

export default class WorldList extends Component<{}, State> {
    componentDidMount() {
        worldApi.getAll()
            .then(ps => this.setState({ worlds: ps }))
    }

    render() {
        if (!this.state) {
            return (
                <h1>Loading...</h1>
            )
        } else {
            return (
                <div>
                    <h1>List of worlds</h1>
                    <br />
                    {this.state.worlds.map(p =>
                        <div key={p.id}>
                            <Link to={"location/world/" + p.id}>
                                {p.shorthand ? "[" + p.shorthand + "] " + p.name : p.name}
                            </Link>
                        </div>
                    )}
                </div>
            )
        }
    }
}
